import React from "react";
import { IconProps } from "types/icon";

const Calendar: React.FC<IconProps> = ({
  size = "16",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 15 16"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        d="M13.75 1.23077H11.875V0.615385C11.875 0.452174 11.8092 0.295649 11.6919 0.180242C11.5747 0.064835 11.4158 0 11.25 0C11.0842 0 10.9253 0.064835 10.8081 0.180242C10.6908 0.295649 10.625 0.452174 10.625 0.615385V1.23077H4.375V0.615385C4.375 0.452174 4.30915 0.295649 4.19194 0.180242C4.07473 0.064835 3.91576 0 3.75 0C3.58424 0 3.42527 0.064835 3.30806 0.180242C3.19085 0.295649 3.125 0.452174 3.125 0.615385V1.23077H1.25C0.918479 1.23077 0.600537 1.36044 0.366116 1.59125C0.131696 1.82207 0 2.13512 0 2.46154V14.7692C0 15.0957 0.131696 15.4087 0.366116 15.6395C0.600537 15.8703 0.918479 16 1.25 16H13.75C14.0815 16 14.3995 15.8703 14.6339 15.6395C14.8683 15.4087 15 15.0957 15 14.7692V2.46154C15 2.13512 14.8683 1.82207 14.6339 1.59125C14.3995 1.36044 14.0815 1.23077 13.75 1.23077ZM3.125 2.46154V3.07692C3.125 3.24013 3.19085 3.39666 3.30806 3.51207C3.42527 3.62747 3.58424 3.69231 3.75 3.69231C3.91576 3.69231 4.07473 3.62747 4.19194 3.51207C4.30915 3.39666 4.375 3.24013 4.375 3.07692V2.46154H10.625V3.07692C10.625 3.24013 10.6908 3.39666 10.8081 3.51207C10.9253 3.62747 11.0842 3.69231 11.25 3.69231C11.4158 3.69231 11.5747 3.62747 11.6919 3.51207C11.8092 3.39666 11.875 3.24013 11.875 3.07692V2.46154H13.75V4.92308H1.25V2.46154H3.125ZM13.75 14.7692H1.25V6.15385H13.75V14.7692Z"
        fill={color}
      />
    </svg>
  );
};

export default Calendar;
