export const IS_BROWSER = typeof window !== "undefined";

export const PAGES = [
  { name: "Home", path: "/", dark: true },
  // { name: "About Us", path: "/about", dark: true },
  { name: "Contact Us", path: "/contact", dark: true },
];

export const AWS_S3_BASE_URL = "https://eyes-of-hope.s3.amazonaws.com/";

export const colors = {
  primary: "#14B2B6",
};

export const contactDetails = {
  addressEg:
    "Building A12, Smart Village Cairo-Alex Desert Road KM28, Cairo, Egypt",
  addressCa: "65 Gloucester, M4Y1L8 Toronto, Ontario",
  email: "info@prepitapp.com",
  linkedIn: "https://www.linkedin.com/company/prepitapp/posts/?feedView=all",
  facebook: "https://www.facebook.com/prepitapprestaurant",
  instagram: "https://www.instagram.com/prepit.app/",
  sendEmail:
    "mailto:info@prepitapp.com?subject=Hello%Enquiry&body=I%20would%20like%a%demo%20",
};
