import React from "react";
import { IconProps } from "types/icon";

const PrepitLogo: React.FC<IconProps> = ({
  size = "20",
  color = "currentColor",
  ...attributes
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 130 32"
      xmlns="http://www.w3.org/2000/svg"
      {...attributes}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3441 18.2386C13.5337 18.2386 15.8973 17.4924 17.4604 15.9999C19.0107 14.5074 19.7859 12.6987 19.7859 10.3588C19.7859 8.01889 18.998 6.10901 17.3841 4.62916C15.7703 3.14932 13.432 2.40308 10.3441 2.40308H1.5249V29.5968H5.09577V18.2386H10.3441ZM16.1515 10.3588C16.1515 11.9525 15.6813 13.1667 14.7282 14.0268C13.7879 14.8869 12.3265 15.3169 10.3441 15.3169H5.09577V5.32482H10.3441C14.2072 5.32482 16.1515 7.00703 16.1515 10.3588Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.0997 18.479L38.6061 29.5968H42.8759L36.0137 18.2007C38.1867 17.7327 39.8006 16.7841 40.8935 15.3549C41.9863 13.9256 42.5201 12.2687 42.5201 10.3968C42.5201 8.86632 42.1643 7.48766 41.4653 6.29873C40.7664 5.1098 39.699 4.14853 38.2757 3.45288C36.8524 2.75723 35.0988 2.40308 33.0401 2.40308H24.2209V29.5968H27.7918V18.479H32.0997ZM33.0401 15.6331H27.7918V5.32482H33.0401C34.9971 5.32482 36.4585 5.7675 37.4116 6.65288C38.3646 7.53825 38.8348 8.80308 38.8348 10.3968C38.8348 11.9904 38.3519 13.2552 37.3861 14.2039C36.4204 15.1525 34.9717 15.6331 33.0401 15.6331Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.1916 18.2386C91.3812 18.2386 93.7449 17.4924 95.3079 15.9999C96.8583 14.5074 97.6334 12.6987 97.6334 10.3588C97.6334 8.01889 96.8201 6.10901 95.219 4.62916C93.6178 3.14932 91.2669 2.40308 88.1916 2.40308H79.3724V29.5968H82.9306V18.2386H88.1916ZM93.9863 10.3588C93.9863 11.9525 93.5161 13.1667 92.5758 14.0268C91.6354 14.8869 90.174 15.3169 88.1916 15.3169H82.9306V5.32482H88.1916C92.0547 5.33325 93.9863 7.01124 93.9863 10.3588Z"
        fill={color}
      />
      <path
        d="M105.627 2.40308V29.5968H102.068V2.40308H105.627Z"
        fill={color}
      />
      <path
        d="M128.526 2.40308V5.28687H121.079V29.5968H117.508V5.28687H110.023V2.40308H128.526Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.2053 6.62752C47.1617 7.5729 48.7054 7.5729 49.6618 6.62752C50.1223 6.17925 50.3754 5.56096 50.3607 4.92001C50.3752 4.29388 50.121 3.69125 49.6618 3.26309C48.6919 2.35078 47.1752 2.35078 46.2053 3.26309C45.7461 3.69125 45.4919 4.29388 45.5064 4.92001C45.4917 5.56096 45.7448 6.17925 46.2053 6.62752ZM75.3314 3.19985H53.8554V6.754H75.3314V3.19985ZM46.2053 17.4038C47.1617 18.3492 48.7054 18.3492 49.6618 17.4038C50.1243 16.9516 50.3774 16.3287 50.3607 15.6836C50.372 15.0581 50.1184 14.4567 49.6618 14.0267C48.6919 13.1144 47.1752 13.1144 46.2053 14.0267C45.7488 14.4567 45.4951 15.0581 45.5064 15.6836C45.4897 16.3287 45.7428 16.9516 46.2053 17.4038ZM75.3314 13.9761H53.8554V17.5176H75.3314V13.9761ZM49.6618 28.7619C48.9673 29.463 47.9148 29.6745 47.0014 29.2964C46.088 28.9183 45.4965 28.0263 45.5064 27.0417C45.4951 26.4162 45.7488 25.8148 46.2053 25.3848C47.1752 24.4725 48.6919 24.4725 49.6618 25.3848C50.1184 25.8148 50.372 26.4162 50.3607 27.0417C50.3744 27.6862 50.1217 28.308 49.6618 28.7619ZM53.8554 25.3216H75.3314V28.8757H53.8554V25.3216Z"
        fill={color}
      />
    </svg>
  );
};

export default PrepitLogo;
