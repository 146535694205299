"use client";

import Hamburger from "@modules/common/components/hamburger";
import { colors, PAGES } from "@lib/constants";
import clsx from "clsx";
import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import { useMobileMenu } from "@lib/context/mobile-menu-context";
import CurvyButton from "@modules/common/components/curvy-button";
import MobileMenu from "@modules/mobile-menu";
import PrepitLogo from "@modules/common/icons/prepit-logo";
import Calendar from "@modules/common/icons/calendar";

const Nav = () => {
  const pathname = usePathname();
  const router = useRouter();
  const [isScrolled, setIsScrolled] = useState(false);

  //useEffect that detects if window is scrolled > 5px on the Y axis
  useEffect(() => {
    if (true) {
      const detectScrollY = () => {
        if (window.scrollY > 5) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };

      window.addEventListener("scroll", detectScrollY);

      return () => {
        window.removeEventListener("scroll", detectScrollY);
      };
    }
  }, []);

  const { toggle } = useMobileMenu();

  return (
    <div
      // className={clsx(" top-0 inset-x-0 z-50 group", {
      //   "!fixed": true,
      // })}
      id="nav"
    >
      <header
        className={clsx(
          "relative h-16 sm:px-[4.5rem] px-4  mx-auto transition-colors bg-transparent border-b border-transparent duration-200 group-hover:bg-secondary group-hover:border-gray-200 ",
          {
            // "!bg-terryWhite !border-gray-200": isScrolled,
          }
        )}
      >
        <nav
          className={clsx(
            "text-gray-900 flex items-center justify-between w-full h-full text-lg-regular transition-colors duration-200",
            {
              "text-secondary group-hover:text-gray-900": !isScrolled,
            }
          )}
        >
          <div className="hidden small:flex items-center h-full">
            <Link
              href="/"
              className="uppercase text-xl-semi "
              aria-label="Go to homepage"
            >
              <PrepitLogo color={colors.primary} size={130} />
            </Link>
          </div>
          <div className="flex small:hidden items-center h-full py-4">
            <Link
              href="/"
              className="uppercase text-xl-semi "
              aria-label="Go to homepage"
            >
              <PrepitLogo color={colors.primary} size={90} />
            </Link>
          </div>
          <div className="flex items-center justify-end flex-1 h-full gap-x-6 basis-0 sm:basis-full">
            <div className="items-center hidden h-full small:flex gap-x-4">
              {PAGES.filter(
                (p) =>
                  p.path !== "/contact" && p.path !== "/" && p.path !== "/about"
              ).map((page, index) => {
                return (
                  <Link
                    area-label={page.name}
                    href={page.path}
                    key={index}
                    className={clsx("hover:underline uppercase", {
                      "text-primary  font-bold": pathname === page.path,
                      "text-regular": pathname !== page.path,
                    })}
                  >
                    {page.name}
                  </Link>
                );
              })}
            </div>
            <div className="hidden small:flex">
              <CurvyButton
                onClick={() => {
                  router.push("/#contact");
                }}
                className="!bg-lightPrimary !text-darkGreen !px-6 !py-2 rounded-full"
              >
                <Calendar size={15} />
                Book a Demo
              </CurvyButton>
            </div>
            {/* <div className="hidden small:flex">
              <CurvyButton
                onClick={() => {
                  router.push("/login");
                }}
                className="!text-terryWhite !px-6 !py-2 rounded-full"
              >
                Login
              </CurvyButton>
            </div> */}
          </div>
          <div className="flex items-center flex-1 h-full basis-0 justify-end">
            <div className="block small:hidden">
              <Hamburger setOpen={toggle} />
            </div>
          </div>
        </nav>
        <MobileMenu />
      </header>
    </div>
  );
};

export default Nav;
